import React, { CSSProperties, FC, useEffect, useState } from 'react';
import {
  ButtonBack,
  CarouselProvider,
  Slider,
  ButtonNext,
} from 'pure-react-carousel';

type MenuDetailPageCarouselProps = {
  children: React.ReactNode;
  totalSlides: number;
  visibleSlides: number;
  naturalSlideWidth?: number;
  naturalSlideHeight?: number;
  step?: number;
  currentSlide?: number | undefined;
  isIntrinsicHeight?: boolean;
  showCarouselButtons?: boolean;
  stretchSlidesToFullWidth?: boolean;
  CarouselProviderClassName?: string;
  CarouselContainerClassName?: string;
  sliderTrayClassName?: string;
  carouselBackButtonClassName?: string;
  carouselNextButtonClassName?: string;
  BackButtonContent?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  NextButtonContent?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  BackButtonStyles?: CSSProperties;
  NextButtonStyles?: CSSProperties;
  mostPopular?: boolean;
}

const MenuDetailPageCarousel: FC<MenuDetailPageCarouselProps> = ({
  children,
  totalSlides,
  visibleSlides,
  naturalSlideWidth = null,
  naturalSlideHeight = null,
  step,
  currentSlide = undefined,
  isIntrinsicHeight = true,
  showCarouselButtons = true,
  stretchSlidesToFullWidth = false,
  CarouselProviderClassName = '',
  CarouselContainerClassName = '',
  sliderTrayClassName = '',
  carouselBackButtonClassName = '',
  carouselNextButtonClassName = '',
  BackButtonContent = null,
  NextButtonContent = null,
  BackButtonStyles = {},
  NextButtonStyles = {},
  mostPopular,
}) => {

  const CarouselProviderProps = {
    naturalSlideWidth: naturalSlideWidth || 100,
    naturalSlideHeight: naturalSlideHeight || 0,
    totalSlides: totalSlides,
    visibleSlides: stretchSlidesToFullWidth ? (visibleSlides > totalSlides ? totalSlides : visibleSlides) : visibleSlides,
    step: step || Math.floor(visibleSlides || 1),
    dragEnabled: totalSlides > visibleSlides && !showCarouselButtons,
    isIntrinsicHeight: isIntrinsicHeight,
    className: `${CarouselProviderClassName}`,
    ...(currentSlide === undefined ? {} : { currentSlide }),
  }
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleNextCarouselButton = () => {
    setActiveIndex((prevIndex) => (prevIndex <= totalSlides - visibleSlides ? prevIndex + visibleSlides : prevIndex));
  };

  const handleBackCarouselButton = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - visibleSlides : prevIndex));
  };

  useEffect(() => {
  },[activeIndex])

  return <>
    <CarouselProvider {...CarouselProviderProps}  className="pl-0 pl-md-3 pr-0 px-md-0">
      <div className={`${CarouselContainerClassName}`}>
        {showCarouselButtons && mostPopular? 
          <ButtonBack style={{ ...BackButtonStyles }} disabled = {totalSlides > visibleSlides ? false : true} className={`goBackSlide ${carouselBackButtonClassName} ${activeIndex <= 0 ? 'mostPopular-button-inactive' : ''}`} onClick={handleBackCarouselButton}>
            <>
              {BackButtonContent ? BackButtonContent : <i className='fas fa-chevron-left'></i>}
            </>
          </ButtonBack>:
          showCarouselButtons && <ButtonBack style={{ ...BackButtonStyles }} className={`goBackSlide ${carouselBackButtonClassName}`}>
          <>
            {BackButtonContent ? BackButtonContent : <i className='fas fa-chevron-left'></i>}
          </>
        </ButtonBack>}
        <Slider classNameTray={`${sliderTrayClassName}`}>
          {children}
        </Slider>
        {showCarouselButtons && mostPopular? 
          <ButtonNext style={{ ...NextButtonStyles }} disabled = {totalSlides > visibleSlides ? false : true} className={`${carouselNextButtonClassName || 'goNextSlide'} ${activeIndex + visibleSlides   >= totalSlides ? 'mostPopular-button-inactive' : ''}`} onClick={handleNextCarouselButton}>
            <>
              {NextButtonContent ? NextButtonContent : <i className='fas fa-chevron-right'></i>}
            </>
          </ButtonNext>:
          showCarouselButtons && <ButtonNext style={{ ...NextButtonStyles }} className={`${carouselNextButtonClassName || 'goNextSlide'}`}>
          <>
            {NextButtonContent ? NextButtonContent : <i className='fas fa-chevron-right'></i>}
          </>
        </ButtonNext>}
      </div>
    </CarouselProvider>
  </>
}
export default MenuDetailPageCarousel;