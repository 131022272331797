import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isEmpty from '../../../Utilities/is_empty';
import MenuSectionFoodItems from './SectionFoodItems';
import RestaurantDummyLogo from '../SVGs/RestaurantDummyLogo.svg';
import { Menu, ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';
// import UserMenuPrefrencesPopup from './UserMenuPrefrencesPopup';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { MarketplaceRef } from '../MarketplacePreview/Marketplace';
import { ApprovedMeetingMenuDetailRef } from '../ApprovedMeetingMenuDetail/ApprovedMeetingMenuDetail';
import { SharedMeetingMenuDetailRef } from '../SharedMeetingMenuDetail/SharedMeetingMenuDetail.tsx';
import { PublicMarketPlaceRef } from '../MarketplacePreview/PublicMarketPlaceWrapper';
import { getMenusOfRestaurant, getMenusOfRestaurantCombos } from '../../../Actions/menuActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { DeliveryMenuRestaurant, MarketplaceMenuRestaurant } from '../../../Interfaces/PayloadsAndResponses/Menu';
import { LocalStorageCompany } from '../../../Interfaces/PayloadsAndResponses/Auth';
import { PublicRestaurantMenuRef } from '../MarketplacePreview/PublicRestaurantMenu';

export type MenuDetailPageParentRef = ApprovedMeetingMenuDetailRef | SharedMeetingMenuDetailRef | MarketplaceRef | PublicMarketPlaceRef | PublicRestaurantMenuRef;

type MenuAreaProps = {
  menu: Menu;
  cutOffPassed?: boolean;
  currentMeeting?: Delivery;
  showCarouselButtons: boolean;
  parentRef: MenuDetailPageParentRef;
  noOfSlidesForUserLiking?: number;
  noOfSlidesForFoodItems: number;
  fromMarketplace?: boolean;
  fromDashboard?: boolean;
  fromInvitedMeeting?: boolean;
  isOpenPopupOnMenuDetailPage?: boolean
  handleMealTypeForMenuCheckAPI?: (value: string) => void;
  handleMealTypeForMenuCheck?: (value: boolean) => void;
  restaurant_id?: number | string;
  mealTypeNumber?: number;
  mealTypeForMenuCheckAPI?: string;
  mealTypeCheckForMenuState?: boolean;
  // menuPageFoodItemPrice?: number;
  // menuPageFoodItemRating?: number;
}
type MenuRestaurantsFilter = (DeliveryMenuRestaurant | MarketplaceMenuRestaurant) & { selected?: boolean }


export enum MenuSections {
  TopPicks = 'Top Picks',
  DynamicSections = 'Dynamic Sections',
  Favorites = 'Favorites',
  MostPopular = 'Most Popular',
  OrderItAgain = 'Order It Again',
  RestaurentSection = 'Restaurent Section'
}

// const prefrencesModalTimeKey = 'preferencesModalTime'
// const ExpiryHours = 24;
// const PreferencesModalExpiryLimit = ExpiryHours * 60 * 60 * 1000;

const MenuArea = ({
  menu,
  parentRef,
  cutOffPassed = false,
  currentMeeting,
  showCarouselButtons,
  fromDashboard = false,
  fromMarketplace = false,
  fromInvitedMeeting = false,
  noOfSlidesForFoodItems = 0,
  isOpenPopupOnMenuDetailPage = false,
  handleMealTypeForMenuCheckAPI,
  handleMealTypeForMenuCheck,
  restaurant_id,
  mealTypeNumber,
  mealTypeForMenuCheckAPI,
  mealTypeCheckForMenuState,
  // menuPageFoodItemPrice,
  // menuPageFoodItemRating
}: MenuAreaProps) => {

  const { isCompanyAdmin, loggedInUserCompany, loggedInUser } = getloggedInUser();
  // const prefrencesModalTimePresent = useRef(!isEmpty(JSON.parse(localStorage.getItem(prefrencesModalTimeKey) ?? '{}')));
  const { recent_orders_data = null, recent_orders_fooditems = null } = menu?.delivery_recent_orders || {};
  const { delivery_dynamic_sections = null } = menu || {};
  const { top_picks = null, most_popular = null, favorites = null, restaurants = [], } = (fromMarketplace ? menu?.menu_of_restaurant : menu?.menu) || {};

   //Companyname without space
   const companyName = loggedInUserCompany ? loggedInUserCompany?.name?.trim() : '';
   const localStorageCompany = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;

  // const getModalRemainingTime = () => {
  //   const currentTime = new Date().getTime();
  //   const prefrencesModalTime = JSON.parse(localStorage.getItem(prefrencesModalTimeKey) ?? '{}');
  //   if (!isEmpty(prefrencesModalTime)) {
  //     if (prefrencesModalTime > currentTime) {
  //       return false
  //     }
  //     else {
  //       localStorage.setItem(prefrencesModalTimeKey, JSON.stringify(currentTime + PreferencesModalExpiryLimit))
  //       return true
  //     }
  //   }
  //   else {
  //     localStorage.setItem(prefrencesModalTimeKey, JSON.stringify(currentTime + PreferencesModalExpiryLimit))
  //     return true
  //   }
  // }

  // Check to ensure that there is minimum a restaurant having a non-empty section
  const sectionsExist = menu && restaurants && restaurants.filter((restaurant) => !isEmpty(restaurant?.sections));
  const isUserPrefrencesSelected = menu && !isEmpty(top_picks) && top_picks && top_picks?.length >= 1;
  const appCueId = `ac-${isCompanyAdmin ? 'admin' : 'user'}-step-${isCompanyAdmin ? '6' : '5'}`
  const isFutureDelivery = !dayjs(currentMeeting?.delivery_at).isBefore(new Date());
  const dispatch: any = useDispatch();
  const reduxCartAddressIds = useSelector((state: ReduxRootState) => state?.server_cart?.address_ids);
  const menuRestaurants : MenuRestaurantsFilter[] = restaurants;
  const screenWidth = window.document.body.clientWidth;
  const comboSelected = JSON.parse(localStorage.getItem('combo_selected') || '{}') as any;
  const [sameDayFilterdRestaurants, setSameDayFilteredRestaurants] = useState<DeliveryMenuRestaurant[]>(restaurants)


  useEffect(() => {
    let restantantsToDisplay = []
    let sameDayIsActive = false
    let regularSchedulerActive = false
    let sameDayCutoffForCurrentUser = isCompanyAdmin ? currentMeeting?.sd_admin_cutoff_at : currentMeeting?.sd_cutoff_at


    if (dayjs(currentMeeting?.admin_cutoff_at).isAfter(new Date()) || dayjs(currentMeeting?.cutoff_at).isAfter(new Date())) {
      regularSchedulerActive = true
    }

    if (currentMeeting?.sd_activation_at && dayjs(currentMeeting?.sd_activation_at).isBefore(new Date()) && dayjs(sameDayCutoffForCurrentUser).isAfter(new Date())) {
      sameDayIsActive = true
    }
    if (sameDayIsActive && regularSchedulerActive) {
      restantantsToDisplay = restaurants
    } else if (sameDayIsActive && !regularSchedulerActive) {
      const sameDayFilter = restaurants?.filter((restaurant, id) => {
        return restaurant?.same_day === true
      })
      restantantsToDisplay = sameDayFilter
    } else {
      restantantsToDisplay = restaurants
    }
    setSameDayFilteredRestaurants(restantantsToDisplay)
    // eslint-disable-next-line
  }, [restaurants])


  // const [isOpenPreferencesModal, setIsOpenPreferencesModal] = useState(!isUserPrefrencesSelected && !isOpenPopupOnMenuDetailPage && getModalRemainingTime);

  // useEffect(() => {
  //   if (isOpenPopupOnMenuDetailPage) {
  //     // If Expiry Time was present already then Donot Remove it Otherwise if it was not present but was set by first render then remove it 
  //     !prefrencesModalTimePresent.current && localStorage.removeItem(prefrencesModalTimeKey);
  //     setIsOpenPreferencesModal(false)
  //   }
  // }, [isOpenPopupOnMenuDetailPage])
   
    // Scroll to specific section


  useEffect(() => {
    const dishSectionId = JSON.parse(sessionStorage?.getItem('dish_section') || '{}')
    ScrollToSpecificSection(dishSectionId[0]?.id)
  }, [])

  useEffect(() => {
  }, [mealTypeForMenuCheckAPI])

    const ScrollToSpecificSection = (id: string) => {
      let yOffset:number = 0
      const elementClickForScroll = document.getElementById(id);
      yOffset = -260; // adjust this value to offset for any fixed header you may have
      const ScrollCalculationSpecificElement = elementClickForScroll && elementClickForScroll.getBoundingClientRect();
      const calculatedPositionForScroll = ScrollCalculationSpecificElement?.top && ScrollCalculationSpecificElement?.top + window.pageYOffset + yOffset;
      window.scrollTo({ top: calculatedPositionForScroll || undefined, behavior: 'smooth' });
    };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let individualBuffetCheck = value === true ? 'buffet' : 'individual'
    handleMealTypeForMenuCheck && handleMealTypeForMenuCheck(!mealTypeCheckForMenuState)
    if (e.target.name === 'mealTypeCheck') {
      handleMealTypeForMenuCheckAPI && handleMealTypeForMenuCheckAPI(individualBuffetCheck)
    }
    const combo_selected = JSON.parse(localStorage.getItem('combo_selected') || '{}') as any;
    if (combo_selected?.combo_selected === 'true') {
      const disabledRestaurants = menuRestaurants?.map((restaurant: any) => {
        return (restaurant?.sections?.length > 0) ? restaurant?.id : ''
      })
      const filteredComboClosedRestaurants: number[] = disabledRestaurants?.filter((id: any) => {
        return id
      })
      dispatch(getMenusOfRestaurantCombos(reduxCartAddressIds, filteredComboClosedRestaurants, individualBuffetCheck))
    }
    else {
      if (typeof restaurant_id !== 'undefined' && typeof mealTypeNumber !== 'undefined') {
        dispatch(getMenusOfRestaurant(+restaurant_id, mealTypeNumber, individualBuffetCheck));
      }
    }
  }

 // Path for favorites Section
 const defaultImagePath = "/imgs/SVGs/favourite.svg";
 const localImagePath = localStorageCompany?.image?.medium?.url;

  return isEmpty(sectionsExist) ?
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <h1> No items found. </h1>
    </div> :
    <>
        <div className={`d-flex flex-column-reverse flex-lg-row align-items-center pt-4 pb-32 ${ screenWidth > 920 && ' justify-content-between'} sectionScroll `} >
          <div className="d-flex">
            <div className="d-flex align-items-center">
            {!isEmpty(favorites) && <button className='btn combo-filter-btn d-flex align-items-center' onClick={() => ScrollToSpecificSection('favorites')}>
              <img src={localImagePath ? localImagePath : defaultImagePath} alt="favorites" className='mr-1 img-square-28'/>
              {`${companyName}'s Favorites`}
            </button>}
            {!isEmpty(recent_orders_data) && fromDashboard && <button className='btn combo-filter-btn d-flex align-items-center' onClick={() => ScrollToSpecificSection('re-order')}>
              <img src="/imgs/SVGs/re-order.svg" alt="Reorder" className='mr-1' />
              Reorder
            </button>}
            {isUserPrefrencesSelected && <button className='btn combo-filter-btn d-flex align-items-center' onClick={() => ScrollToSpecificSection('top-picks')} >
              <img src="/imgs/SVGs/topPick.svg" alt="Top Picks for You" className='mr-1' />
              Top Picks for You
            </button>}
            {!isEmpty(most_popular) && <button className='btn combo-filter-btn d-flex align-items-center' onClick={() => ScrollToSpecificSection('most-popular')} >
              <img src="/imgs/SVGs/Most Popular.svg" alt="popular-restaurant" className='mr-1' />
              Most Popular
            </button>}
          </div>
        {restaurants && <button className='btn combo-filter-btn d-flex align-items-center' onClick={() => ScrollToSpecificSection('restaurant-menu')} >
          <img src="/imgs/SVGs/All Restaurants.svg" alt="all-restaurant" className='mr-1'/>
          Restaurant(s) Menu
        </button>}
        </div>
        {(!loggedInUserCompany.self_signup && !isEmpty(loggedInUser) && fromMarketplace && !comboSelected.combo_selected && restaurants[0]?.buffet_menu_exists) && <div className={`custom-control custom-switch pull-right d-inline buffet-menu-switch buffet-menu-button-switch menu-page-menu-type-toggle ${mealTypeCheckForMenuState ? 'buffet-selected' : ''}`}>
          <input type='checkbox' className='custom-control-input buffet-menu-input' id='mealTypeCheck' name='mealTypeCheck'
            checked={mealTypeCheckForMenuState} onChange={onChange}
          />
          <label className='custom-control-label buffet-menu-label buffet-menu-button-switch' htmlFor='mealTypeCheck'>&nbsp;&nbsp;</label>
        </div>}
      </div>
    {/* Favorites Section */}
     {!isEmpty(favorites) &&
        <>
          <div className='d-flex align-items-center ml-3' id='favorites'>
            {/* <div className='menu-section-icon mr-2 ml-2'>
            <i className='fas fa-heart'></i>
            </div> */}
            <img src={localImagePath ? localImagePath : defaultImagePath} alt="favorites" className='img-square-28'/>
            <h4 className='m-0 ml-3 font-weight-bold'>{`${companyName}'s Favorites`}</h4>
          </div>
          <MenuSectionFoodItems
            cutOffPassed={cutOffPassed}
            currentMeeting={currentMeeting}
            noOfSlidesForFoodItems={noOfSlidesForFoodItems}
            showCarouselButtons={showCarouselButtons}
            parentRef={parentRef}
            fromMarketplace={fromMarketplace}
            sectionType={MenuSections.Favorites}
            fromDashboard={fromDashboard}
            fromInvitedMeeting={fromInvitedMeeting}
            foodItems={favorites ?? []}
            // recentOrdersData={recent_orders_data ?? []}
          />
        </> 
        
      }
      {/* Order It Again OR Rate your order Section */}
      {!isEmpty(recent_orders_data) && fromDashboard ?
        <>
          <div className='d-flex align-items-center ml-3' id='re-order'>
            {/* <div className='menu-section-icon mr-2 ml-2'>
              <i className={`${isFutureDelivery ? 'fas fa-redo-alt' : 'fa fa-star-o'}`} aria-hidden='true'></i>
            </div> */}
            <img src="/imgs/SVGs/re-order.svg" alt="Reorder" className='img-square-32' />
            <h4 className='m-0 ml-3 font-weight-bold'>{isFutureDelivery ? 'Reorder' : 'Rate Your Items'}</h4>
          </div>
          <MenuSectionFoodItems
            cutOffPassed={cutOffPassed}
            currentMeeting={currentMeeting}
            noOfSlidesForFoodItems={noOfSlidesForFoodItems}
            showCarouselButtons={showCarouselButtons}
            parentRef={parentRef}
            fromMarketplace={fromMarketplace}
            sectionType={MenuSections.OrderItAgain}
            fromDashboard={fromDashboard}
            fromInvitedMeeting={fromInvitedMeeting}
            foodItems={recent_orders_fooditems ?? []}
            recentOrdersData={recent_orders_data ?? []}
          />
        </> :
        ''
      }
      {/* New Section */}
      {isEmpty(top_picks) || (!isUserPrefrencesSelected && cutOffPassed) || fromInvitedMeeting ? '' :
        <>
          {isUserPrefrencesSelected ?
            <div className={`d-flex flex-column flex-sm-row justify-content-between align-items-center w-100`} id='top-picks'>
              <div className='order-2 order-sm-1 w-100'>
                <div className={`d-flex align-items-center pb-2 ml-3`}>
                  {/* <div className='menu-section-icon mr-2 ml-2' id={appCueId}>
                    <i className='fas fa-user'></i>
                  </div> */}
                  <img src="/imgs/SVGs/topPick.svg" alt="Top Picks for You" className='img-square-32' id={appCueId}/>
                  <h4 className='m-0 ml-3 font-weight-bold'> Top Picks for <span className='text-capitalize'>{'you'}</span></h4>
                </div>
              </div>
            </div> : ''}
          {isUserPrefrencesSelected &&
            // ? <UserMenuPrefrencesPopup
            //   isOpen={isOpenPreferencesModal}
            //   closeModal={() => { setIsOpenPreferencesModal(false) }}
            //   currentMeeting={currentMeeting}
            //   fromMarketplace={fromMarketplace}
            //   parentRef={parentRef}
            //   dietaries={top_picks?.dietaries || []}
            // /> :
            <MenuSectionFoodItems
              cutOffPassed={cutOffPassed}
              currentMeeting={currentMeeting}
              noOfSlidesForFoodItems={noOfSlidesForFoodItems}
              showCarouselButtons={showCarouselButtons}
              parentRef={parentRef}
              fromMarketplace={fromMarketplace}
              sectionType={MenuSections.TopPicks}
              fromDashboard={fromDashboard}
              fromInvitedMeeting={fromInvitedMeeting}
              foodItems={top_picks || []}
            />
          }
        </>
      }
      {!isEmpty(delivery_dynamic_sections) && !fromMarketplace &&
        <>
          {delivery_dynamic_sections?.dynamic_sections?.map(section =>
            isEmpty(section.fooditems) ? null :
              <React.Fragment key={section.id}>
                <div className='d-flex align-items-center ml-3'>
                  <div className='menu-section-icon mr-2 ml-2'>
                    <i className={`${section.icon ?? 'fas fa-heart'}`}></i>
                  </div>
                  <h4 className='m-0'>{section.name}</h4>
                </div>
                <MenuSectionFoodItems
                  cutOffPassed={cutOffPassed}
                  currentMeeting={currentMeeting}
                  noOfSlidesForFoodItems={noOfSlidesForFoodItems}
                  showCarouselButtons={showCarouselButtons}
                  parentRef={parentRef}
                  sectionType={MenuSections.DynamicSections}
                  fromMarketplace={fromMarketplace}
                  fromDashboard={fromDashboard}
                  fromInvitedMeeting={fromInvitedMeeting}
                  foodItems={section.fooditems || []}
                />
              </React.Fragment>)
          }
        </>
      }
      {/* Most Popular Section */}
      {!isEmpty(most_popular) &&
        <>
          <div className='d-flex align-items-center ml-3' id='most-popular'>
            {/* <div className='menu-section-icon mr-2 ml-2'>
              <i className='fas fa-star'></i>
            </div> */}
             <img src="/imgs/SVGs/Most Popular.svg" alt="popular-restaurant" className='img-square-32' />
            <h4 className='m-0 ml-3 font-weight-bold'>Most Popular</h4>
          </div>
          <MenuSectionFoodItems
            cutOffPassed={cutOffPassed}
            currentMeeting={currentMeeting}
            noOfSlidesForFoodItems={noOfSlidesForFoodItems}
            showCarouselButtons={showCarouselButtons}
            parentRef={parentRef}
            sectionType={MenuSections.MostPopular}
            fromMarketplace={fromMarketplace}
            fromDashboard={fromDashboard}
            fromInvitedMeeting={fromInvitedMeeting}
            foodItems={most_popular || []}
          />

        </>
      }
      {/* Delivery Menu Restaurants Section */}
      {sameDayFilterdRestaurants?.map((_restaurants, firstIndex) => {
        return <div key={_restaurants?.id + '-' + firstIndex} style={{ position: 'relative' }} id='restaurant-menu'>
          <div className='row'>
            <div className='col-12 m-auto text-left'>
              {!isEmpty(_restaurants && _restaurants.sections) ? _restaurants.sections && _restaurants.sections.map((section) => {
                let foodItems = section.fooditems;
                return <div id={`${section?.id}`} className={'mainSection' + _restaurants.id + section.id} key={section?.id}>
                  {!isEmpty(section.fooditems) &&
                    <>
                      <div className='d-flex align-items-center ml-3'>
                        {_restaurants.logo_url ?
                          <div className='mr-2 mr-sm-2 menu-restaurant-logo ml-2' style={{ backgroundImage: `url(${_restaurants.logo_url})`, width: 55, height: 55 }}></div> :
                          <img width={55} height={55} src={RestaurantDummyLogo} alt={_restaurants?.name} className='mr-3 rounded-circle' />
                        }
                        <div>
                          <h6 className='d-flex align-items-center restaurent-name m-0 text-capitalize'>{_restaurants.name}</h6>
                          <h4 className='m-0 text-capitalize'>{section?.name}</h4>
                        </div>
                      </div>
                      <MenuSectionFoodItems
                        cutOffPassed={cutOffPassed}
                        currentMeeting={currentMeeting}
                        noOfSlidesForFoodItems={noOfSlidesForFoodItems}
                        showCarouselButtons={showCarouselButtons}
                        sectionType={MenuSections.RestaurentSection}
                        parentRef={parentRef}
                        fromMarketplace={fromMarketplace}
                        fromDashboard={fromDashboard}
                        fromInvitedMeeting={fromInvitedMeeting}
                        foodItems={foodItems}
                      />
                    </>
                  }
                </div>
              }
              ) : ''
              }
            </div>
          </div>
        </div>
      })}
    </>
}

export default MenuArea;