export const ArrayOfLength = (length: number) => Array.from({ length }, (v, i) => i);

export const arraySorting = (arrayToBeSorted: any[], sortBy: string) => {
    let sortedRestaurants = arrayToBeSorted
    if (sortBy === 'distance') {
        sortedRestaurants = sortedRestaurants?.sort((a, b) => ((a?.distance ?? 0) > (b?.distance ?? 0)) ? 1 : -1) ?? [];
    } else if (sortBy === 'rating') {
        sortedRestaurants = sortedRestaurants?.sort((a, b) => ((a?.average_rating ?? 0) < (b?.average_rating ?? 0)) ? 1 : -1) ?? [];
    } else {
        sortedRestaurants = arrayToBeSorted
    }

    return sortedRestaurants
}