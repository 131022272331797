import { CART_ITEMS, LOADING, CART_DETAILS, CART_AT_SERVER, SAVE_ORDER_DATA_FOR_CONFIRMATION, SAVE_ITEM_NAME_FOR_NOTIFICATION, CART_ADDRESS_IDS_LOCAL, SAVE_CART_ORDER_DATA_FOR_CONFIRMATION } from './types';
import axios from 'axios';
import { getError } from './errorActions';
import { AppThunkAction } from '../store';
import { CartDetails, ItemDataType, PlaceCartOrderPayload, UpdateCartOrderPayload } from '../Interfaces/PayloadsAndResponses/Cart';
import { DeliveryFeePayload } from '../Interfaces/PayloadsAndResponses/Cart';
import { UserHeaders } from '../Interfaces/PayloadsAndResponses/Auth';
import { showToastNotification } from '../Utilities/showToastNotification';
import isEmpty from '../Utilities/is_empty';

export const addToCart = (requestItemData: ItemDataType, requestCartData: CartDetails, requestDeliveryFee: DeliveryFeePayload, cartAddressIds: number[], isOrderForConfirmation?: boolean, emptyCartNotification?: () => void, itemAddedToCart?: () => void, loaderHandle?: () => void): AppThunkAction => dispatch => {
  // dispatch({
  //   type: CART_ITEMS,
  //   payload: requestItemData
  // });
  // dispatch({
  //   type: CART_DETAILS,
  //   payload: requestCartData
  // });
  // dispatch(getDeliveryFee(requestDeliveryFee))

  // axios.defaults.baseURL = process.env.REACT_APP_SENPEX_BASE_URL;

  if (isOrderForConfirmation) {
    dispatch({
      type: SAVE_CART_ORDER_DATA_FOR_CONFIRMATION,
      payload: {
        requestItemData,
        requestCartData,
        // delivery_cost: res.data.delivery_cost,
        requestDeliveryFee,
        cartAddressIds
      }
    });
    emptyCartNotification?.()
  } else {
    dispatch({
      type: CART_ITEMS,
      payload: requestItemData
    });
    itemAddedToCart && itemAddedToCart?.()
    loaderHandle && loaderHandle?.()
    // showToastNotification(`${requestItemData?.itemName} added to cart`, ' ', false, 'top-right')
    dispatch({
      type: CART_DETAILS,
      payload: requestCartData
    });
    dispatch({
      type: CART_ADDRESS_IDS_LOCAL,
      payload: cartAddressIds
    });
    //Removed the senpex API from FrontEnd
    // const axioasInstance = axios.create({
    //   baseURL: process.env.REACT_APP_SENPEX_BASE_URL
    // });

    // dispatch(setLoading());
    // axioasInstance
    //   .post(`/admin/webhooks/senpex/get_price`, requestDeliveryFee)
    //   .then(res => { 
    //     if(res.data.success) {
    //       dispatch({
    //         type: GET_DELIVERY_FEE,
    //         payload: res.data.delivery_cost
    //       })
    //       dispatch({
    //         type: CART_ITEMS,
    //         payload: requestItemData
    //       });
    //       itemAddedToCart && itemAddedToCart?.()
    //       loaderHandle && loaderHandle?.()
    //       // showToastNotification(`${requestItemData?.itemName} added to cart`, ' ', false, 'top-right')
    //       dispatch({
    //         type: CART_DETAILS,
    //         payload: requestCartData
    //       });
    //       dispatch({
    //         type: CART_ADDRESS_IDS_LOCAL,
    //         payload: cartAddressIds
    //       });
    //     } else {
    //       showToastNotification('Item cannot be added, unable to calculate delivery cost.', 'Oops!', true, 'top-left')
    //     }
    //   })
    //   .catch(err =>
    //     dispatch(getError(err))
    //   );
  }

};

//SenPex
// export const getDeliveryFee = (deliveryFee: DeliveryFeePayload, incDecLoaderHandler: (value: boolean) => void): AppThunkAction => dispatch => {
//   incDecLoaderHandler && incDecLoaderHandler(true)
//   // axios.defaults.baseURL = process.env.REACT_APP_SENPEX_BASE_URL;
//   const axioaInstance = axios.create({
//     baseURL: process.env.REACT_APP_SENPEX_BASE_URL
//   });

//   // dispatch(setLoading());
//   axioaInstance
//     .post(`/admin/webhooks/senpex/get_price`, deliveryFee)
//     .then(res => { 
//       incDecLoaderHandler(false)
//       if(res.data.success) {
//         dispatch({
//           type: GET_DELIVERY_FEE,
//           payload: res.data.delivery_cost
//         })
//       }
//     })
//     .catch(err =>
//       dispatch(getError(err))
//     );
// };

// Status Loading
export const setLoading = () => {
  return {
    type: LOADING
  };
}

export const updateCart = (cartData: any, slug: string, cartPopupDisplayHandling: () => void ): AppThunkAction => dispatch => {
  axios
    .put(`/carts/${slug}/checkout`, {
      "runningmenu": cartData
    },
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      cartPopupDisplayHandling && cartPopupDisplayHandling()
      // showToastNotification('Your ratings and reviews help us improve our food and service.', 'Thank you');
      // ratingAddedCallback && ratingAddedCallback();
    })
    .catch(err => {
      // ratingAddedCallback && ratingAddedCallback()
      dispatch(getError(err))
    });
};

export const getCartAfterLogin = (date: any, deliverySlug: string): AppThunkAction => dispatch => {
  axios
    .get(`/carts?delivery_at=${date}${isEmpty(deliverySlug) ? '' : `&slug=${deliverySlug}`}`,
      {
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      if(res.data.message) {
      } else {
        dispatch({
          type: CART_AT_SERVER,
          payload: res.data
        });

      }
    })
    .catch(err =>
      dispatch(getError(err))
    );
};

export const placeCartOrder = (orderData: PlaceCartOrderPayload, headers?: UserHeaders, itemName?: string, emptyCartNotification?: () => void, itemAddedToCart?: () => void, loaderHandle?: ()=>void, placeCartOrderSuccessHandler?: (response: any) => void): AppThunkAction => dispatch => {
  dispatch(setLoading());
  const cartHeaders = headers ? {headers} : {headers: JSON.parse(localStorage.getItem('headers') || '{}')}
  
  if(placeCartOrderSuccessHandler) {
    axios
    .post(`/carts?delivery_at=${orderData.runningmenu.delivery_at}`, orderData, cartHeaders)
    .then(res => {
      // placeCartOrderSuccessHandler?.(res.data)
      loaderHandle && loaderHandle?.()
      if(res.data.pending_cart_exist === true) {
        localStorage.setItem('order_data_confirmation', JSON.stringify(orderData));
      } else {
        dispatch({
          type: CART_AT_SERVER,
          payload: res.data
        })
        itemName && itemAddedToCart?.()
      }

      placeCartOrderSuccessHandler(res.data)
      // window.location.href = `/menu/${dayjs(res.data.delivery_at).format('M-D-YYYY')}/${res.data?.slug}`
      
      // loaderHandle && loaderHandle?.()
      dispatch({
        type: CART_AT_SERVER,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch(getError(err))
    );
  } else {

    axios
      .post(`/carts?delivery_at=${orderData.runningmenu.delivery_at}`, orderData, cartHeaders)
        // {
        //   headers: JSON.parse(localStorage.getItem('headers') || '{}')
        // })
      .then(res => {
        loaderHandle && loaderHandle?.()
        if(res.data.pending_cart_exist === true) {
          dispatch({
            type: SAVE_ORDER_DATA_FOR_CONFIRMATION,
            payload: orderData
          })
          dispatch({
            type: SAVE_ITEM_NAME_FOR_NOTIFICATION,
            payload: itemName
          })
          emptyCartNotification?.()
        } else {
          // (orderDeliveryDate && !token) && dispatch(getDeliveryDates(orderDeliveryDate, orderDeliveryDate));
          dispatch({
            type: CART_AT_SERVER,
            payload: res.data
          })
          itemName && itemAddedToCart?.()
          // itemName && showToastNotification(`${itemName} added to cart`, ' ', false, 'top-right')
          // dispatch({
          //   type: EMPTY_CART
          // })
        }
      })
      .catch(err =>
        dispatch(getError(err))
      );
  }
};

export const updateCartOrder = (orderData: UpdateCartOrderPayload, slug: string, alteringController?: () => void, cartItems?: any): AppThunkAction => dispatch => {

  axios
    .put(`/carts/${slug}`, orderData,
    {
      headers: JSON.parse(localStorage.getItem('headers') || '{}')
    })
    .then(res => {
      alteringController && alteringController()
      dispatch({
        type: CART_AT_SERVER,
        payload: res.data
      })
      if (orderData?.runningmenu?.orders_attributes && orderData?.runningmenu?.orders_attributes[0]?.status === 'cancelled') {
        if (cartItems?.length <= 1) {
          // stopped for redirection when last item is deleted from cart
          // window.location.reload()
        }
      }
    })
};

export const cancelCartOrder = (slug: string, cancel_reason: string, deleteSucessHandler: () => void): AppThunkAction => dispatch => {
  axios
    .delete(`/carts/${slug}`,
      {
        data: { runningmenu: { cancel_reason: cancel_reason } }, 
        headers: JSON.parse(localStorage.getItem('headers') || '{}')
      })
    .then(res => {
      showToastNotification('Meeting is cancelled successfully.', 'Thank you');
      // ratingAddedCallback && ratingAddedCallback();
      deleteSucessHandler && deleteSucessHandler()
    })
    .catch(err => {
      // ratingAddedCallback && ratingAddedCallback()
      dispatch(getError(err))
    });
};


// export const validateCartDeliveryAddress = (address: string): AppThunkAction => dispatch => {
//   axios
//     .post(`https://addressvalidation.googleapis.com/v1:validateAddress
//     `, {},
//       {
//         // headers: JSON.parse(localStorage.getItem('headers') || '{}')
//       }).then(() => {

//       })
//     .catch(err =>
//       dispatch(getError(err))
//     );
// }

// export const updateCartAddress = (orderData: PlaceCartOrderPayload, slug: string, alteringController?: () => void, cartItems?: any): AppThunkAction => dispatch => {

//   axios
//     .put(`/carts/${slug}`, orderData,
//       {
//         headers: JSON.parse(localStorage.getItem('headers') || '{}')
//       })
//     .then(res => {
//       alteringController && alteringController()
//       dispatch({
//         type: CART_AT_SERVER,
//         payload: res.data
//       })
//     })
// };