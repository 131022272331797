import React, { FC } from 'react'
import { Combo } from '../../../Interfaces/PayloadsAndResponses/Marketplace';
import ComboRestaurant from './ComboRestaurant';

type ComboCardProps = {
  getComboomboMenu: (combo: any) => void;
  comboFoodItemTotalCalculator: (combo: any) => any;
  goToComboMenu: (combo: any) => boolean;
  combo: Combo
}
const ComboCard: FC<ComboCardProps> = ({ combo, goToComboMenu, getComboomboMenu, comboFoodItemTotalCalculator }) => {

  return (
    <div className="combo-restaurant-info-card col-lg-12 g-4 p-3 bg-white" onClick={() => !goToComboMenu(combo) && getComboomboMenu(combo)}>
      <div className="combo-card-overlay">
        <img src="/imgs/closed.png" alt="close" />
      </div>
      {/* Reataurant Card heading No. of restaurant showing */}
      <h5 className={`${goToComboMenu(combo) ? 'combo-heading-disabled' : 'combo-heading'}`}>Mix & Match Menus: {`${combo?.restaurants.length} Restaurants `}</h5>
      {/* Restaurant card states Vegitarions and others*/}
      <div className="d-flex align-items-center pt-2 pt-md-0 combo-details">
        <span className='ml-0 text-gray-100'> {`${comboFoodItemTotalCalculator(combo)} Items -`}</span>
        <span className='ml-1 text-gray-100'> {`${combo?.veg_percentage}% Vegetarian`} </span>
      </div>
      <div className='container'>
        <div className='row'>
          {combo?.restaurants?.map((restaurant, index) =>
            <div key={index} className="col-md-6 px-0 pr-0 pr-md-4 pb-3 pb-md-4">
              <ComboRestaurant restaurant={restaurant} />
            </div>
          )}
        </div>
      </div>

      {/* Explore more button */}
      <div className="explore-button mt-2 cursor-pointer" onClick={() => !goToComboMenu(combo) && getComboomboMenu(combo)}>
        <p className='explore-menu-btn d-inline'>Explore Menu</p>
      </div>
    </div>
  )
}

export default ComboCard