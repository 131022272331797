import React from 'react'
import Skeleton from 'react-loading-skeleton'

const MarketplaceCombosSkeleton = () => {
  return (
    <div className="combo-restaurant-info-card col-lg-12 g-4 p-3 bg-white">
      <div className='d-flex my-1'>
        <Skeleton className='mr-2' height={25} width={20} />  <h5 className='combo-heading'>{`Restaurants `}</h5>
      </div>
      <div className="d-flex align-items-center pt-2 pt-md-0 combo-details">
        <div>
          <span className='ml-0 text-gray-100'>Items</span>
          <Skeleton className='mx-2' height={20} width={30} />
        </div>
        <div className='d-flex'>
          <Skeleton className='mx-2' height={20} width={30} />
          <span className='ml-1 text-gray-100'> {`% Vegetarian`} </span>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          {[1, 2, 3, 4, 5, 6].map((restaurant, index) =>
            <div key={restaurant + index} className="col-md-6 px-0 pr-0 pr-md-4 pb-3 pb-md-4">
              <div className='d-flex mt-1'>
                <Skeleton className='rounded-circle' height={40} width={40} />
                <div>
                  <Skeleton className='mx-2' height={20} width={70} />
                  <div className='d-flex mt-1'>
                    <Skeleton className='mx-2' height={18} width={25} />
                    <Skeleton className='mx-2' height={18} width={25} />
                    <Skeleton className='mx-2' height={18} width={50} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="explore-button mt-2">
        <p className='explore-menu-btn d-inline'>Explore Menu</p>
      </div>
    </div>
  )
}


export default MarketplaceCombosSkeleton