import { GET_MARKETPLACE_SUGGESTIONS, GET_MARKETPLACE_RESTAURANTS, RESET_STATE, MARKETPLACE_PAGE, GET_MARKETPLACE_COMBOS, GET_MARKETPLACE_FAVORITES, MARKETPLACE_MOST_POPULAR_RESTAURANTS, MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU, MARKETPLACE_FILTERS, MARKETPLACE_SECTION_SCROLL, MARKETPLACE_SECTION_SCROLL_PERMISSION, GET_DYNAMICTITLES, MARKETPLACE_POPULAR_DISHES } from '../Actions/types';
import { MarketplaceSuggestions } from '../Interfaces/ReduxInterface/ReduxRootState';

const initialState: MarketplaceSuggestions = {
  suggestions: null,
  restaurants: null,
  eRestaurantLoading: null,
  marketplaceCombos: null,
  marketplaceCombosLoading: false,
  marketplaceFavorites: null,
  marketplaceFavoritesLoading: false,
  showSearchBarOnMarketPlace: false,
  most_popular: null,
  marketPlaceMostPopularRestaurantsLoading: false,
  trending_dishes: null,
  marketplaceTrendingDishesLoading: false,
  new_restaurants_near_you: null,
  marketPlaceNewRestaurantNearYouLoading: false,
  marketplace_filters: null,
  marketPlaceFiltersLoading: false,
  menuTypeLoader: false,
  marketPlaceSectionScroll: null,
  permissionToScroll: false,
  dynamicTitles: null,
}

const marketplaceReducer = (state: MarketplaceSuggestions = initialState, action: any): MarketplaceSuggestions => {
  switch (action.type) {
    case GET_MARKETPLACE_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.payload
      }
    case GET_MARKETPLACE_RESTAURANTS:
      return {
        ...state,
        restaurants: action.paginated
          ? state.restaurants
            ? { ...state.restaurants, restaurants: [...state.restaurants.restaurants, ...action.payload.restaurants] }
            : action.payload
          : action.payload,
        eRestaurantLoading: action?.loading,
        menuTypeLoader: action?.menuTypeloading
      }
    case GET_MARKETPLACE_COMBOS:
      return {
        ...state,
        marketplaceCombos: action?.payload,
        marketplaceCombosLoading: action?.loading
      }
    case GET_MARKETPLACE_FAVORITES:
      return {
        ...state,
        marketplaceFavorites: action?.payload,
        marketplaceFavoritesLoading: action?.loading
      }
    case MARKETPLACE_PAGE:
      return {
        ...state,
        showSearchBarOnMarketPlace: action.payload
      }
    case MARKETPLACE_SECTION_SCROLL:
      return {
        ...state,
        marketPlaceSectionScroll: action.payload
      }
    case MARKETPLACE_SECTION_SCROLL_PERMISSION:
      return {
        ...state,
        permissionToScroll: action.payload
      };
    case MARKETPLACE_MOST_POPULAR_RESTAURANTS:
      return {
        ...state,
        most_popular: action?.payload,
        marketPlaceMostPopularRestaurantsLoading: action?.loading
      }
    case MARKETPLACE_POPULAR_DISHES:
      return {
        ...state,
        trending_dishes: action?.payload,
        marketplaceTrendingDishesLoading: action?.loading
      }
    case MARKETPLACE_NEW_RESTAURANTS_NEAR_YOU:
      return {
        ...state,
        new_restaurants_near_you: action?.payload,
        marketPlaceNewRestaurantNearYouLoading: action?.loading
      }
    case MARKETPLACE_FILTERS:
      return {
        ...state,
        marketplace_filters: action?.payload,
        marketPlaceFiltersLoading: action?.loading
      }
    case GET_DYNAMICTITLES:
      return {
        ...state,
        dynamicTitles: action?.payload,
      }
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default marketplaceReducer;