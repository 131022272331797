import React, { FC, CSSProperties, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProfile } from "../../Actions/profileActions";
import CustomPopup from '../../Common/Popups/CustomPopUp';
import { getloggedInUser } from "../../Utilities/getloggedInUser";
import isEmpty from "../../Utilities/is_empty";
import CartDetails from "./CartDetails";
import ServerCartDetails from "./ServerCartDetails";
// import { addressValidationFromGoogleValidationAPI } from "../../Utilities/functions";
// import { useSelector } from "react-redux";
// import { ReduxRootState } from "../../Interfaces/ReduxInterface/ReduxRootState";
import { User } from "../../Interfaces/PayloadsAndResponses/Auth";

const cartStyles = {
  top: 0,
  right: 0,
  bottom: 0,
  width: '99vw',
  zIndex: 6,
  // maxWidth: 1000,
  maxWidth: 456,
  height: '100vh',
  position: 'fixed',
  transition: '.7s all',
  backgroundColor: '#fafcfe',
} as CSSProperties;

type CartProps = {
  isOpenSidebar: boolean;
  closeSidebar: () => void;
  fromPublicMarketPlace?: boolean;
  fromPublicRestaurantMenu?: boolean;
  fromMarketPlace?: boolean;
  cartItemCount?: number;
  // reloadIfCartChanged?: () => void;
  onItemAddedToCart?: (itemImage: string | null, itemName: string) => void;
  cartPage?: string;
  continueOrdering?: () => void;
}

const Cart: FC<CartProps> = ({ fromPublicMarketPlace = false, fromMarketPlace = false,  isOpenSidebar, cartItemCount, closeSidebar, onItemAddedToCart, cartPage, continueOrdering }) => {
  const dispatch: any = useDispatch()
  const { loggedInUser: user } = getloggedInUser();
  // const { cart_at_login } = useSelector(((state: ReduxRootState) => state.server_cart));

  // const [wrongDeliveryAddress,setWrongDeliveryAddress] = useState<boolean>(false)
  useEffect(() => {
    // Handle Two Scroll Issue
    if (isOpenSidebar) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpenSidebar])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}') as User;

    if (!fromPublicMarketPlace && user?.ss_user) {
      const validatingCartAddress = async () => {
        
        // const verificationResuktFromGoogle = await addressValidationFromGoogleValidationAPI(cart_at_login?.formatted_address)
        // const verificationResuktFromGoogleSecondAddress = await addressValidationFromGoogleValidationAPI(cart_at_login?.address_line)

        // const apiVerdict = verificationResuktFromGoogle?.verdict
        // if (verificationResuktFromGoogleSecondAddress?.addressComplete) {
        //   setWrongDeliveryAddress(false)
        // } else {
        //   // addressVerification = false
        //   setWrongDeliveryAddress(true)
        // }
      }
      // const data = await 
      validatingCartAddress()
      // counter = 1
    }
    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (!isEmpty(user)) {
      dispatch(getProfile())
    }
     // eslint-disable-next-line
  }, [])

  return (
    <CustomPopup
      hideBackDrop={!isOpenSidebar}
      allowScrolling
      onPopUpClose={closeSidebar}
    >
      <div
        id='DeliveryOrderDetailsSidebar'
        style={{ 
          ...cartStyles,
          transform: isOpenSidebar ? 'translateX(0)' : 'translateX(100%)'
        }}
      >
        {fromPublicMarketPlace ? 
          <CartDetails
            closeSidebar={closeSidebar}
            isOpenSidebar={isOpenSidebar}
            fromPublicMarketPlace
            onItemAddedToCart={() => onItemAddedToCart}
            continueOrdering={continueOrdering}
          />
          :
          <ServerCartDetails
            closeSidebar={closeSidebar}
            isOpenSidebar={isOpenSidebar}
            fromMarketPlace={fromMarketPlace}
            // reloadIfCartChanged={reloadIfCartChanged}
            onItemAddedToCart={() => onItemAddedToCart}
            cartPage={cartPage}
            addressValidationCheck={ false} //{wrongDeliveryAddress ?? false}
            continueOrdering={continueOrdering}
          />
        }


      </div>
    </CustomPopup>
  );
}

export default Cart