import React from 'react'
import Skeleton from 'react-loading-skeleton'
//CSS
import './MarketPlace.css'

const MarketPlaceSectionSkeleton = () => {
  return (
    <div className='d-flex'>
      {[1, 2, 3]?.map((slide, index) => {
        return <React.Fragment key={slide + index}>
          <div>
            <Skeleton className='mr-4 rounded-corners' height={210} width={280} />
            <div className='d-flex mt-1'>
              <Skeleton className='rounded-circle' height={40} width={40} />
              <div>
                <Skeleton className='mx-2' height={20} width={70} />
                <div className='d-flex mt-1'>
                  <Skeleton className='mx-2' height={18} width={50} />
                  <Skeleton className='mx-2' height={18} width={40} />
                  <Skeleton className='mx-2' height={18} width={70} />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      })}
    </div>
  )
}

export default MarketPlaceSectionSkeleton