import React, { FC } from 'react'
import { MarketplaceRestaurant } from '../../../Interfaces/PayloadsAndResponses/Marketplace';

type ComboRestaurantProps = {
  restaurant: MarketplaceRestaurant;
}

const ComboRestaurant: FC<ComboRestaurantProps> = ({ restaurant }) => {

  return (
    <div>
      <div className="combo-card-info">
        <div className="d-flex">
          <div className={`restaurant-card-logo-wrapper ${!restaurant.is_open && 'restaurant-close'}`}>
            {restaurant?.logo_url?.url ?
              <div className='mr-1 mr-sm-2 restaurant-logo' style={{ backgroundImage: `url(${restaurant?.logo_url?.url})`, width: 40, height: 40 }}></div>
              :
              <div id='autoThumbnailParent'>
                <div id='autoThumbnailChild'>
                  <span className='hidden-xs text-capitalize' id='autoHiddenDrop'>{restaurant?.name?.charAt(0)} </span>
                </div>
              </div>
            }
          </div>
          <div className="text-overflow-handling">
            <div className='d-flex align-items-center'><h6 className={`combo-restaurant-name mb-1 text-truncate ${!restaurant.is_open && 'restaurant-close'}`}>{restaurant.name}</h6>
              {!restaurant.is_open && <span className='btn-close ml-3'>CLOSED</span>}
            </div>
            <div className={`d-flex align-items-center ${!restaurant.is_open && 'restaurant-close'}`}>
              <div className="text-overflow-handling">
                <div className="d-flex align-items-center">
                  {+restaurant?.average_rating === 0.0 ? null : <><div className='d-inline float-right' >
                    <div className='d-flex align-items-center'>
                      <i className="fas fa-star marketplace-combo pr-1 fa-xs" aria-hidden="true"> </i>
                      <p className='fs-18 fw-normal m-0 pr-2 rating-number' style={{ fontSize: '0.95rem', color: "#6E6E73" }}>{restaurant?.average_rating}</p>
                    </div>
                  </div>
                  </>
                  }
                  <div className='card-text mb-0' style={{ 'height': 'auto' }}>
                    <div className='d-flex justitfy-content-center align-items-center mx-1'>
                      {restaurant?.average_rating > 0 && restaurant?.cuisines && restaurant.cuisines.length > 0 && (
                        <i className="fas fa-circle small-dot" style={{ color: "#6E6E73" }}></i>
                      )}
                      {restaurant?.cuisines && restaurant?.cuisines?.split(',')?.slice(0, 1)?.map((cuisine, index) => (
                        <div key={index} className='d-flex align-items-center'>
                          <span className='mr-2 individual-combo-badge ml-2'>{cuisine}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComboRestaurant