import React, { CSSProperties } from 'react';
import dayjs from 'dayjs';
import MenuSection from './MenuSection';
import { MarketplaceRef } from '../MarketplacePreview/Marketplace'
import { MenuDetailPageParentRef, MenuSections } from '../Common/MenuArea';
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { FoodItem, RecentOrder } from '../../../Interfaces/PayloadsAndResponses/Menu';
import { ApprovedMeetingMenuDetailRef } from '../ApprovedMeetingMenuDetail/ApprovedMeetingMenuDetail';
import { SharedMeetingMenuDetailRef } from '../SharedMeetingMenuDetail/SharedMeetingMenuDetail.tsx';
import { LocalStorageCompany } from '../../../Interfaces/PayloadsAndResponses/Auth';

type FoodItemSlideProps = {
  parentRef: MenuDetailPageParentRef,
  deliveryDetails: Delivery | undefined,
  fooditem: FoodItem,
  cutOffPassed: boolean;
  sectionType: MenuSections;
  className?: string;
  isDraggingCarouselItems?: boolean;
  fromInvitedMeeting?: boolean;
  fromDashboard?: boolean;
  fromMarketplace?: boolean;
  recentOrder?: RecentOrder | null;
  foodItemSlideStyles?: CSSProperties;
}

const FoodItemSlide = ({
  parentRef,
  deliveryDetails,
  className = '',
  fooditem,
  fromInvitedMeeting = false,
  fromDashboard = false,
  fromMarketplace = false,
  sectionType,
  cutOffPassed,
  recentOrder,
  foodItemSlideStyles = {}
}: FoodItemSlideProps) => {

  const ParentRefForCreatedMeeting = parentRef as ApprovedMeetingMenuDetailRef | SharedMeetingMenuDetailRef;
  const ParentRefForNewMeeting = parentRef as MarketplaceRef;

  const openFoodItemDetail = () => {
    if (cutOffPassed) { // If Cutoff Is Passed Or Delivery Detail Is Not Present then donot open Order Popup.
      return;
    }
    const { id, address_id, per_user_copay, per_user_copay_amount, menu_type, admin_cutoff_at, sd_activation_at, sd_admin_cutoff_at, sd_cutoff_at, cutoff_at, field_required } = deliveryDetails || {};

    // Params for openModalWithItem
    // openModalWithItem(order: RecentOrder | null, fooditem: FoodItem, order_id: number, address_id: number | undefined, currentMeetingSlugOrId: number | string | undefined, per_user_copay_amount?: string, isRecentOrder: boolean = false) {

    if (fromInvitedMeeting && ParentRefForCreatedMeeting && deliveryDetails) {
      ParentRefForCreatedMeeting.orderPopup?.openModalWithItem(
        recentOrder ?? null, // Already Created Order Detail
        fooditem, // item
        0, // order_id
        address_id!, // address_id
        id!, // currentMeetingSlugOrId
        per_user_copay_amount! // per_user_copay_amount
      );
      ParentRefForCreatedMeeting.orderPopup?.setState({
        per_user_copay: per_user_copay ?? 0,
        model_type: 'add',
        admin_cutoff_at: admin_cutoff_at ?? '',
        same_day_admin_cutoff_at: sd_admin_cutoff_at ?? '',
        cutoff_at: cutoff_at ?? '',
        menu_type: menu_type ?? '',
        same_day_cutoff: sd_cutoff_at ?? '',
        same_day_activation_at: sd_activation_at ?? '',
      });
    } else if (fromDashboard && ParentRefForCreatedMeeting && deliveryDetails) {
      ParentRefForCreatedMeeting.orderPopup?.openModalWithItem(
        recentOrder ?? null, // Already Created Order Detail
        fooditem, // item
        0, // order_id
        address_id!, // address_id
        id!, // currentMeetingSlugOrId
        per_user_copay_amount!, // per_user_copay_amount
        sectionType === MenuSections.OrderItAgain // isRecentOrder
      );
      ParentRefForCreatedMeeting.orderPopup?.setState({
        field_required:
          (ParentRefForCreatedMeeting as ApprovedMeetingMenuDetailRef).state?.field_required === false
            ? false
            : Boolean(field_required),
        per_user_copay: per_user_copay ?? 0,
        model_type: 'add',
        admin_cutoff_at: admin_cutoff_at ?? '',
        same_day_admin_cutoff_at: sd_admin_cutoff_at ?? '',
        menu_type: menu_type ?? '',
        cutoff_at: cutoff_at ?? '',
        same_day_cutoff: sd_cutoff_at ?? '',
        same_day_activation_at: sd_activation_at ?? '',
      });
    } else if (fromMarketplace && ParentRefForNewMeeting) {
      const { selectedRestaurants, selectedCompanyAddressID, startDate, mealType, meal_type } = ParentRefForNewMeeting.state || {}
      ParentRefForNewMeeting.props?.orderPopup?.openModalWithItem(
        recentOrder ?? null, // Already Created Order Detail
        fooditem, // item
        0, // order_id
        undefined, // address_id
        undefined, // currentMeetingSlugOrId will be empty in case of Marketplace
        '0', // per_user_copay_amount
      );
      ParentRefForNewMeeting.props?.orderPopup?.setState({
        marketPlacePopUp: true,
        model_type: 'add',
        selectedRestaurants: selectedRestaurants,
        selectedCompanyAddressID: selectedCompanyAddressID,
        deliveryAt: startDate ?? '',
        field_required: true,
        menu_type: mealType,
        meal_type: meal_type,
      });
    }
    if (ParentRefForCreatedMeeting) {
      ParentRefForCreatedMeeting.initialPrice = 0;
    }
  }

  const isFutureDelivery = !dayjs(deliveryDetails?.delivery_at).isBefore(new Date())
  const company = JSON.parse(localStorage.getItem('company') || '{}') as LocalStorageCompany;
  const disableStateForCartMenu = company?.self_signup === true && deliveryDetails?.status === 'approved'

  return <div
    className={`mx-2 ${className} ${(disableStateForCartMenu || deliveryDetails?.enterprise_converted ) && 'disabled-fooditem'} ${((cutOffPassed || fooditem.in_budget === false) && sectionType !== MenuSections.OrderItAgain) ? 'disabled-fooditem' : ''}`}
    onClick={openFoodItemDetail}
    style={foodItemSlideStyles}
  >
    {(fromMarketplace && ParentRefForNewMeeting) ?
      <MenuSection
        isFutureDelivery={true}
        per_user_copay={ParentRefForNewMeeting?.company?.user_copay}
        per_user_copay_amount={ParentRefForNewMeeting?.company?.copay_amount}
        fooditem={fooditem}
        fromMarketplace
        sectionType={sectionType}
        recentOrderIdForRating={recentOrder?.id} // Order Id For Rating Purpose
        pricesToShown={ParentRefForNewMeeting.state.mealTypeCheck ? 0 : ParentRefForNewMeeting.getPricesToShown(parseFloat(`${fooditem.price}`), fooditem.skip_markup)}
        description={ParentRefForNewMeeting.truncateString(fooditem?.description?.toString(), 100) as string}
      /> :
      deliveryDetails ?
        <MenuSection
          isFutureDelivery={isFutureDelivery}
          fromDashboard={fromDashboard}
          per_user_copay={deliveryDetails?.per_user_copay}
          per_user_copay_amount={deliveryDetails?.per_user_copay_amount}
          remaining_budget={deliveryDetails?.remaining_budget}
          invitedMeeting={fromInvitedMeeting}
          sectionType={sectionType}
          recentOrderIdForRating={recentOrder?.id} // Order Id For Rating Purpose
          fooditem={fooditem}
          pricesToShown={ParentRefForCreatedMeeting.getPricesToShown(parseFloat(`${fooditem.price}`), fooditem.skip_markup, parseFloat(deliveryDetails?.per_meal_budget))}
          description={ParentRefForCreatedMeeting.truncateString(fooditem?.description?.toString(), 100) as string}
        /> : ''
    }
  </div>
}

export default FoodItemSlide;